import React, { useEffect } from "react"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import FreeTrial from '../components/free-trial'
import TwoColumn from "../components/two-column"
import ComparisonTable from "../components/comparison-table"
import Testimonial from '../components/testimonial'
import SwiperTabs from '../components/swiper-tabs'
import PlanAction from '../components/plan-action'
import CustomerJourney from '../components/customer-journey'
import BadgeContent from "../components/badge-component"
// import GetStarted from '../components/get-started'
import {
  bannerContent,
  comparisonTableContent,
  trialContent,
  planActionContent,
  customerJourneyContent,
  developersContent,
  // getStartedContent
} from "../lib/home-content"
import { swiperTabsContent } from "../lib/swiper-tabs-content"
// import { testimonialContent } from "../lib/testimonial-content"
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment"
import { getCookie, setCookie } from "../utils"

const badgeData = {
  section: "home",
  link: true,
  FallBadges: [
    {
      img: require("../images/high-performer-winter-2022.svg"),
      altText: 'high-performer-winter-2022',
    },
    {
      img: require("../images/best-relationship-fall-2021.svg"),
      altText: 'best-relationship-fall-2021',
    },
    {
      img: require("../images/most-implementable-winter-2022.svg"),
      altText: 'most-implementable-winter-2022',
    },
    {
      img: require("../images/best-usability-winter-2022.svg"),
      altText: 'best-usability-winter-2022',
    },
    {
      img: require("../images/momentum-leader-winter-2022.svg"),
      altText: 'momentum-leader-winter-2022',
    },
    {
      img: require("../images/users-most-likely-to-recommend-2021.svg"),
      altText: 'users-most-likely-to-recommend-2021',
    },
  ],
}

const IndexPage = () => {
  useEffect(() => {
    const initializeAnalytics = () => {
      const userId = uuidv4();
      getCookie('billsby_guid') === '' && setCookie('billsby_guid', userId, 30);
      ReactGA.initialize('G-CY7NFRKD5B', { gaOptions: { userId, titleCase: false } });
      ReactGA.set({ cookie_prefix: 'billsby_cookie' })
      ReactGA.send({ hitType: "pageview", title: 'Billsby - Marketing website' });
      ReactGA.event('visited_marketing_website');
    }

    initializeAnalytics();
  });


  return (
    <Layout isHeaderTransparent={true}>
      <SEO
        title="Highest rated subscription billing management software | Billsby"
        description="Online subscription billing management software with customizable, advanced billing services, and reporting insights | Billsby"
        url="https://www.billsby.com/"
      />

      <div className="home-banner-with-badge">
        <TwoColumn content={bannerContent} />
        <BadgeContent content={badgeData} />
      </div>
      <SwiperTabs
        tabs={swiperTabsContent}
        title="If you can dream it, we can bill it."
        description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans."
      />
      <PlanAction content={planActionContent} />
      <CustomerJourney content={customerJourneyContent} />
      <ComparisonTable content={comparisonTableContent} />
      <TwoColumn content={developersContent} />
      {/* <GetStarted content={getStartedContent} /> */}
      <Testimonial />
      <FreeTrial content={trialContent} />
    </Layout>
  )
}

export default IndexPage
